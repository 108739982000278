import { Injectable } from "@angular/core";
import { BffClient } from "./bff-client";

const CheckForPopupClosedInterval = 500;
const DefaultPopupFeatures = "location=no,toolbar=no,width=500,height=500,left=100,top=100;";
const DefaultPopupTarget = "_blank";

export type UserData = Record<string, unknown>;

@Injectable({ providedIn: "root" })
export class BffUserManager {
  private readonly bffClient = new BffClient();
  private readonly bffEvents = new BffUserManagerEvents();

  private user: UserData;
  private csrfToken: string;

  get events() {
    return this.bffEvents;
  }

  getUser(): UserData {
    return this.user;
  }

  getCsrfToken(): string {
    return this.csrfToken;
  }

  removeUser(): void {
    // Here is a problem, we continue to use the existing CSRF token
    // (which is likely associated with the user being removed), but
    // on the client side we pretend there's no user anymore.
    // If requests are going to be made in this state, they are likely
    // going to work, because there will still be a user on the server side
    // and the token will match. At the same time, there's no point in asking
    // the server for a new token, because there's a user there and we would
    // just get a token for that user back.
    this.setUser(null, this.csrfToken);
  }

  async signinPopup(args?: any): Promise<UserData> {
    const features = this.getPopupFeatures(args);
    const target = this.getPopupTarget(args);
    const id = this.generatePopupCallbackId();
    const returnUrl = this.getPopupCallbackUrl(id);
    const loginUrl = this.bffClient.getLoginUrl({ returnUrl });
    await this.popup(id, loginUrl, target, features);
    await this.reloadUser();
    return this.user;
  }

  private async popup(
    callbackId: string,
    url: string,
    target: string,
    features: string,
    errorIfClosed = true,
  ): Promise<void> {
    const popup = window.open(url, target, features);
    if (!popup) {
      throw new Error("Failed to open a popup window.");
    }

    let closeInterval: number;
    const closePromise = new Promise<void>((resolve, reject) => {
      closeInterval = window.setInterval(() => {
        if (popup.closed) {
          clearInterval(closeInterval);
          if (errorIfClosed) {
            reject(new Error("Popup window closed"));
          } else {
            resolve();
          }
        }
      }, CheckForPopupClosedInterval);
    });

    const callbackPromise = new Promise((resolve) => {
      this.addPopupCallback(callbackId, (url: string) => {
        popup.close();
        resolve(url);
      });
    });

    try {
      await Promise.race([callbackPromise, closePromise]);
    } finally {
      this.removePopupCallback(callbackId);
      clearInterval(closeInterval);
    }
  }

  private getPopupTarget(args: any) {
    return (args?.popupWindowTarget as string) || DefaultPopupTarget;
  }

  private getPopupFeatures(args?: any) {
    return (args?.popupWindowFeatures as string) || DefaultPopupFeatures;
  }

  signinRedirect(args?: { returnUrl?: string | false }): void {
    const returnUrl = args?.returnUrl;
    window.location.href = this.bffClient.getLoginUrl({ returnUrl });
  }

  signoutRedirect(args?: { returnUrl?: string | false }): void {
    window.location.href = this.getLogoutUrl(args?.returnUrl);
  }

  async signoutPopup(args?: any): Promise<void> {
    const features = this.getPopupFeatures(args);
    const target = this.getPopupTarget(args);
    const id = this.generatePopupCallbackId();
    const returnUrl = this.getPopupCallbackUrl(id);
    const logoutUrl = this.getLogoutUrl(returnUrl);

    this.removeUser();
    await this.popup(id, logoutUrl, target, features, false);

    // if there was really a logout, we need a new CSRF token
    await this.reloadUser();
  }

  async init(user: UserData, csrfToken: string): Promise<void> {
    this.setUser(user, csrfToken);
    if (!this.user) {
      await this.reloadUser();
    }
  }

  private getLogoutUrl(returnUrl?: string | false): string {
    const logoutUrl = this.user["bff:logout_url"] as string;
    return this.bffClient.getLogoutUrl({ logoutUrl, returnUrl });
  }

  private getPopupCallbackUrl(id: string, baseUrl?: string): string {
    // By default, assume that 'popup-callback' is in our base.
    const returnUrl = new URL("popup-callback.html", baseUrl || document.baseURI);
    returnUrl.searchParams.set("id", id);
    return returnUrl.pathname + returnUrl.search;
  }

  private generatePopupCallbackId(): string {
    // not great
    return String(Math.random()).replace(".", "");
  }

  private getPopupCallbackName(id: string): string {
    return `popupCallback_${id}`;
  }

  private addPopupCallback(id: string, callback: (url: string) => void): void {
    const callbackName = this.getPopupCallbackName(id);
    (window as any)[callbackName] = callback;
  }

  private removePopupCallback(id: string): void {
    const callbackName = this.getPopupCallbackName(id);
    delete (window as any)[callbackName];
  }

  private async reloadUser(): Promise<void> {
    const { user, csrfToken } = await this.bffClient.getUser();
    this.setUser(user, csrfToken);
  }

  private setUser(user: UserData, csrfToken: string) {
    this.csrfToken = csrfToken;

    let unload = false;
    let load = false;
    if ((this.user && !user) || (this.user && user && this.user.sub !== user.sub)) {
      unload = true;
    }

    if ((!this.user && user) || (this.user && user && this.user.sub !== user.sub)) {
      load = true;
    }

    if (unload) {
      this.user = null;
      this.bffEvents.unload();
    }
    if (load) {
      this.user = user;
      this.bffEvents.load(this.user);
    }

    if (!unload && !load) {
      this.user = user;
    }
  }
}

type UserLoadedCallback = (user: UserData) => void;
type UserUnloadedCallback = () => void;

class BffUserManagerEvents {
  private userLoaded: UserLoadedCallback[] = [];
  private userUnloaded: UserUnloadedCallback[] = [];

  addUserLoaded(callback: UserLoadedCallback) {
    this.userLoaded.push(callback);
  }

  removeUserLoaded(callback: UserLoadedCallback) {
    this.userLoaded = this.userLoaded.filter((it) => it !== callback);
  }

  addUserUnloaded(callback: UserUnloadedCallback) {
    this.userUnloaded.push(callback);
  }

  removeUserUnloaded(callback: UserUnloadedCallback) {
    this.userUnloaded = this.userUnloaded.filter((it) => it !== callback);
  }

  load(user: UserData): any {
    this.userLoaded.forEach((it) => it(user));
  }

  unload(): any {
    this.userUnloaded.forEach((it) => it());
  }
}
